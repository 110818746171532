// Modules
import {useCallback} from 'react';

// Config
import {getDeezerUrl as getDeezerLegacyUrl} from '@app/modules/location';
import {useSearchParams} from 'next/navigation';
import {useRouter} from 'next/router';

type GetDeezerUrl = (
	path?: string,
	options?: Partial<GetDeezerUrlOptions>,
) => string;

type GetDeezerUrlOptions = {
	withLocale: boolean;
	withQuery?: boolean;
};

/**
 * @deprecated if you can try to use the `getDeezerLegacyUrl` module instead
 * this hook is based on the usage of useRouter which prevents us from
 * migrating to app router
 */
export const useLocation = () => {
	const router = useRouter();
	const params = useSearchParams();
	const getDeezerUrl: GetDeezerUrl = useCallback(
		(path = '/', options?: Partial<GetDeezerUrlOptions>) => {
			options = {
				withLocale: true,
				withQuery: true,
				...options,
			};
			return getDeezerLegacyUrl({
				locale: options.withLocale ? router.locale : undefined,
				pathname: path,
				params,
			});
		},
		[params, router.locale],
	);

	return {
		getDeezerUrl,
	};
};
