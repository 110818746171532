import {useUser} from '@deezer/react-user';
import {useLocation} from './use-location';
import {getAccountUrl, getDeezerUrl} from '@app/modules/location';
import {useRouter} from 'next/router';
import {useSearchParams} from 'next/navigation';

export const useAuthLink = () => {
	const [user] = useUser();
	const {locale} = useRouter();
	const qp = useSearchParams();

	return {
		getRegistrationLink: (redirectAfterReg?: string): string => {
			const url = new URL(getAccountUrl({pathname: '/signup/', locale}));
			url.search = qp.toString();
			if (redirectAfterReg) {
				url.searchParams.set('redirect_uri', redirectAfterReg);
			}
			return user?.isConnected()
				? (redirectAfterReg ?? getDeezerUrl({pathname: '/', locale}))
				: url.href;
		},
		getLoginLink: (redirectAfterLogin?: string): string => {
			const url = new URL(getAccountUrl({pathname: '/login/', locale}));
			url.search = qp.toString();
			if (redirectAfterLogin) {
				url.searchParams.set('redirect_uri', redirectAfterLogin);
			}
			return user?.isConnected()
				? (redirectAfterLogin ?? getDeezerUrl({pathname: '/', locale}))
				: url.href;
		},
	};
};

type AllPlatformFallback = {all: string};
type SpecificFallbacks = {
	/**
	 * Where to go on an android device if your user does not have the app.
	 * Defaults to the app's homepage on the PlayStore.
	 *
	 * Can be used to redirect to an alternative journey such as the reg page
	 * or a custom store page
	 */
	android: string;
	/**
	 * Where to go on an iOS device if your user does not have the app.
	 * Defaults to the app's homepage on the AppStore.
	 *
	 * Can be used to redirect to an alternative journey such as the reg page
	 * or a custom store page
	 */
	ios: string;
	/**
	 * Overrides where to go on the web or on non iOS and Android mobiles
	 */
	webOrOther: string;
};
type MobileDesktopFallbacks = {
	/**
	 * Overrides where to go on the mobile if user does not have the app.
	 * Defaults to the app's homepage on the platform's application store.
	 */
	mobile: string;
	/**
	 * Overrides where to go on the web or on non iOS and Android mobiles
	 */
	webOrOther: string;
};
type Fallbacks =
	| AllPlatformFallback
	| SpecificFallbacks
	| MobileDesktopFallbacks;

const isAllPlatformFallback = (obj: any): obj is AllPlatformFallback => {
	return typeof obj.all !== 'undefined';
};
const isSpecificFallbacks = (obj: any): obj is SpecificFallbacks => {
	return typeof obj.android === 'string' && typeof obj.ios === 'string';
};
const isMobileDesktop = (obj: any): obj is MobileDesktopFallbacks => {
	return typeof obj.mobile === 'string' && obj.webOrOther === 'string';
};

/** Bypass referer is added to tell GA analysts to
 * bypass referer analytics on these links. */
const addBypassreferer = (url: string): string => {
	const tmpUrl = new URL(url);
	tmpUrl.searchParams.set('bypassReferer', '1');
	return tmpUrl.href;
};
const addArlToMobileLink = (url: string, arl: string): string => {
	const tmpUrl = new URL(url);
	tmpUrl.searchParams.set('arl', arl);
	return tmpUrl.href;
};

type DeezerPageLinkOptions = {
	/**
	 * iOS does not natively have deeplinks capabilities after app dl.
	 * In order to mitigate this issue, firebase creates an ugly preview
	 * page for iOS only:
	 * This copies the target deeplink when user clicks on the button.
	 * Deezer iOS app then uses the clipboard value to go to the targetted
	 * deeplink.
	 *
	 * The `skipPreview` param can be added to bypass this page if you
	 * don't want to redirect to something else than the home.
	 *
	 * Also useful to make sure user escapes webviews
	 */
	skipPreview?: boolean;

	/**
	 * By adding user's arl to the deeplink you'll autolog the user in
	 * the app!
	 */
	arl?: string;
};
export const createDeezerPageLink = (
	applink: string,
	fallbacks?: Fallbacks,
	options?: DeezerPageLinkOptions,
): string => {
	const baseUrl = new URL('https://dzr.page.link');

	// Deezer specific tracking param
	const targetUrl = new URL(applink);
	targetUrl.searchParams.set('bypassreferer', '1');

	// Default Query Params
	baseUrl.searchParams.set('apn', 'deezer.android.app');
	baseUrl.searchParams.set('amv', '6190600');
	baseUrl.searchParams.set('ibi', 'com.deezer.Deezer');
	baseUrl.searchParams.set('isi', '292738169');
	baseUrl.searchParams.set('imv', '7.11.0');

	// Behaviors Query Params
	baseUrl.searchParams.set('link', targetUrl.href);

	if (fallbacks) {
		let androidLink = '';
		let iosLink = '';
		let webLink = '';

		if (isAllPlatformFallback(fallbacks)) {
			androidLink = fallbacks.all;
			iosLink = fallbacks.all;
			webLink = fallbacks.all;
		}

		if (isMobileDesktop(fallbacks)) {
			androidLink = fallbacks.mobile;
			iosLink = fallbacks.mobile;
			webLink = fallbacks.webOrOther;
		}

		if (isSpecificFallbacks(fallbacks)) {
			androidLink = fallbacks.android;
			iosLink = fallbacks.ios;
			webLink = fallbacks.webOrOther;
		}

		webLink = addBypassreferer(webLink);
		iosLink = addBypassreferer(iosLink);
		androidLink = addBypassreferer(androidLink);

		/** Skips the preview screen on iOS. Waring, might be
		 * less reliable when opening links from webviews */
		if (options?.skipPreview) {
			baseUrl.searchParams.set('efr', '1');
		}
		if (options?.arl) {
			addArlToMobileLink(iosLink, options.arl);
			addArlToMobileLink(androidLink, options.arl);
		}
		baseUrl.searchParams.set('ifl', iosLink);
		baseUrl.searchParams.set('afl', androidLink);
		baseUrl.searchParams.set('ofl', webLink);
	}
	return baseUrl.href;
};

export const useLareleveLink = () => {
	const {getDeezerUrl} = useLocation();
	const {getRegistrationLink} = useAuthLink();
	const [user] = useUser();

	const loggedLink = createDeezerPageLink(
		getDeezerUrl('/channels/lareleve', {withLocale: false}),
		{
			android:
				'https://play.google.com/store/apps/details?id=deezer.android.app&listing=lareleve_android',
			ios: 'https://apps.apple.com/us/app/deezer-music-podcast-player/id292738169?ppid=ad01efcc-ec74-499b-9c5f-db65482c6eec',
			webOrOther: getDeezerUrl('/channels/lareleve'),
		},
		{skipPreview: false},
	);
	const regLink = getRegistrationLink(loggedLink);
	const unloggedLink = createDeezerPageLink(
		getDeezerUrl('/channels/lareleve', {withLocale: false}),
		{
			all: regLink,
		},
		{skipPreview: true},
	);
	return user?.isConnected() ? loggedLink : unloggedLink;
};
